import React, { useState, useEffect } from 'react';
import SectionHeading from '../../Common/SectionHeading';
import Image from 'next/image';
import Link from 'next/link';
import BorderBtn from '../../Common/BorderBtn';

const FeaturedSection = ({ featureData, customClass, eventSlugPrefix="eular-conference", slugPrefix="eular-2022" }) => {
    const firstSection = (card, index) => {
        return (
            <div key={index} className='col-span-12 lg:col-span-12'>
                <div className='mb-10 bg-white shadow'>
                    <div className="grid grid-cols-12 gap-4">
                        <div className="col-span-12 lg:col-span-6">
                            <Link href={process.env.APP_URL + `/${eventSlugPrefix}` + `/${slugPrefix}/` + card?.slug}>
                                <Image priority={true}
                                    className="rounded-lg w-full h-auto rounded-br-[200px]"
                                    alt={card.image_alt}
                                    src={process.env.IMG_CDN + card.image}
                                    height={2000}
                                    width={2000}
                                />
                            </Link>
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                            <div className='p-4'>
                                <Link href={process.env.APP_URL + `/${eventSlugPrefix}` + `/${slugPrefix}/` + card?.slug}>
                                    <h3 className="my-4 text-xl font-semibold">{card.title}</h3>
                                </Link>
                                <p className="line-clamp-6">{card.short_description}</p>
                                <BorderBtn
                                    customClass="bg-[#1b5df3] border-[#1b5df3] text-white font-semibold"
                                    buttonTitle="Read Expert's Analysis"
                                    buttonLink={process.env.APP_URL + `/${eventSlugPrefix}` + `/${slugPrefix}/` + card?.slug}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div className="flex flex-col justify-center w-11/12 mx-auto my-6 xl:max-w-screen max-w-screen-2xl lg:my-10" >
            {/* <SectionHeading
                title="Featured"
            /> */}
            <div className='grid grid-cols-12 gap-4'>
                {featureData?.map((card, index) => (
                    <React.Fragment key={index}>
                        {index == 0 ? firstSection(card, index) :
                            <div className='col-span-12 lg:col-span-4'>
                                <div className={"rounded-br-[200px] group object-cover relative overflow-hidden transition-all duration-500 mb-0 cursor-pointer before:absolute before:left-0 before:bottom-0 before:w-full before:h-full before:bg-gradient-to-b before:from-black before:via-opacity-0 before:to-transparent before:transform before:-rotate-180 before:transition-all before:duration-500 before:before:rounded-lg" + customClass}>
                                    <div className="relative overflow-hidden">
                                        <Image priority={true}
                                            width={2000}
                                            height={2000}
                                            className={`w-full h-80 lg:h-96 object-cover group-hover:scale-110 transition-all duration-500 group-hover:duration-500`}
                                            alt={card.image_alt}
                                            src={process.env.IMG_CDN + card.image}
                                        />
                                    </div>
                                    <div className="absolute block left-0 bottom-[60px] overflow-hidden pl-4 pr-10 lg:group-hover:bottom-[180px] group-hover:bottom-[120px] transition-all duration-500 group-hover:transition-all group-hover:duration-500 group-hover:delay-300">
                                        <Link href={process.env.APP_URL + `/${eventSlugPrefix}` + `/${slugPrefix}/` + card?.slug}>
                                            <h3 className="text-xl font-bold text-white line-clamp-3">
                                                {card.title}
                                            </h3>
                                        </Link>
                                    </div>
                                    <div className="bg-[#ffffff33]  w-full absolute block left-0 bottom-0 z-10">
                                        <BorderBtn
                                            buttonTitle="Read Expert's Analysis"
                                            buttonLink={process.env.APP_URL + `/${eventSlugPrefix}` + `/${slugPrefix}/` + card?.slug}
                                            customClass="rounded-none font-semibold border-0 w-full bg-[#1b5df3] border-[#1b5df3] text-white py-3 px-5 text-left mx-auto"
                                        />
                                    </div>
                                    <div className="absolute lg:block hidden left-0 bottom-[-180px] px-5 group-hover:bottom-[100px] transition-all duration-500 group-hover:transition-all group-hover:duration-500 group-hover:delay-300">
                                        <p className="relative m-0 text-white line-clamp-3">
                                            {card.short_description}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        }
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};

export default FeaturedSection;
