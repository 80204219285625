import React from 'react'
import Image from 'next/image'
import Link from 'next/link'
import BorderBtn from '../../Common/BorderBtn'
import SectionHeading from '../../Common/SectionHeading'
import { OurServicesList } from '../DataSet/MainData'

export default function OurServicesSection({ data = null, reportUrl }) {
    return (
        <>
            {(data) &&
                <>
                    <div className="w-11/12 xl:max-w-screen max-w-screen-2xl flex flex-col justify-center mx-auto lg:my-10 my-6">
                        {/* <SectionHeading
                            title="Our Services"
                        /> */}
                        <div className="grid grid-cols-12 xl:gap-24 gap-4">
                            <div className="xl:col-span-6 col-span-12">
                                <div>
                                    <h2 className="text-4xl font-semibold">{data?.title}</h2>
                                    <div>{data.shortDescription}</div>
                                    <BorderBtn
                                        customClass="bg-[#1b5df3] border-[#1b5df3] text-white"
                                        buttonTitle={data.btnName}
                                        buttonLink={process.env.APP_URL+data?.link}
                                    />
                                </div>
                            </div>
                            <div className="xl:col-span-6 col-span-12">
                                <div className="grid grid-cols-12 gap-4">
                                    {data?.items?.map((item, index) => (
                                        <div key={index} className="md:col-span-6 col-span-12">
                                            <div className="lg:block flex">
                                                <Link href={process.env.APP_URL+item?.link}>
                                                    <Image priority={false}
                                                        width={2000}
                                                        height={2000}
                                                        className="lg:w-14 lg:h-14 lg:m-0 w-8 h-8 mr-3 mb-2"
                                                        alt={item?.altText ? item?.altText : item?.title ? item?.title : 'DelveInsight'}
                                                        src={process.env.IMG_CDN + item?.imageSrc}
                                                    />
                                                </Link>
                                                <Link href={process.env.APP_URL+item?.link}>
                                                    <h3 className="text-lg font-semibold">{item?.title}</h3>
                                                </Link>
                                            </div>
                                            <p className="mt-2 line-clamp-3">{item?.description}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}
