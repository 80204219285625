const OurServicesList = {
    title: "Delivering value through our expansive service offerings",
    shortDescription: <>
        <p>Considering the ever-changing nature of the oncology therapy field, it&apos;s crucial to establish a competitive advantage for your business as soon as possible. Our range of consulting services and market research resources positions us well to guide you through obstacles while maximizing your strengths and opportunities.</p>
        <p>We provide valuable insights to enhance your competitive edge and develop successful growth strategies. Join us on a journey to uncover opportunities and avoid pitfalls, as we simplify the process of optimizing your return on investment through our expert guidance</p>
    </>,
    btnName:"Explore Oncology",
    link: "/report-store.php?therapeutic_areas=Oncology",
    items: [
        {
            title: "Portfolio Management",
            description: "Involve us to help you collect insights on which asset could hold the most potential in the future.",
            imageSrc: "img/events/svg/portfolio-management.svg",
            altText: "Portfolio Management",
            link: "/consulting/pharmaceutical-portfolio-management-solutions",
        },
        {
            title: "Market Assessment",
            description: "Our 11-year forecast provides foresight for effective strategy-building for successful market entry",
            imageSrc: "img/events/svg/market-assessment.svg",
            altText: "Market Assessment",
            link: "/consulting/market-assessment-services",
        },
        {
            title: "Competitive Intelligence",
            description: "Our research expertise delivers real-time information that elevates your business decisions",
            imageSrc: "img/events/svg/competitive-Intelligence.svg",
            altText: "Competitive Intelligence",
            link: "/consulting/competitive-intelligence-services",
        },
        {
            title: "Pipeline Assessment",
            description: "We assist in navigating the tricky drug development terrain by providing intelligence assessments",
            imageSrc: "img/events/svg/pipeline-assessment.svg",
            altText: "Pipeline Assessment",
            link: "/consulting/pipeline-assessment-services",
        },
    ]
};
const OurServicesListATS2022 = {
    title: "Delivering value through our expansive service offerings",
    shortDescription: <>
        <p>Taking into account the highly dynamic landscape of respiratory therapy area, it is of utmost importance to attain competitive edge early on in your business. With our repository of consulting solutions and market research offerings, we are well-positioned to advise you on how to navigate the barriers as well as leverage your strengths and opportunities.</p>
        <p>We impart insights aimed at leveraging competitive advantage and formulating effective business growth strategies. Journey with us to realize opportunities as well as pitfalls, as we make it easier for you to capitalize your return on investment through our expert advice.</p>
    </>,
    btnName:"Explore Respiratory",
    link: "/report-store.php?therapeutic_areas=Respiratory",
    items: [
        {
            title: "Portfolio Management",
            description: "Involve us to help you collect insights on which asset could hold the most potential in the future.",
            imageSrc: "img/events/svg/portfolio-management.svg",
            altText: "Portfolio Management",
            link: "/consulting/pharmaceutical-portfolio-management-solutions",
        },
        {
            title: "Market Assessment",
            description: "Our 11-year forecast provides foresight for effective strategy-building for successful market entry",
            imageSrc: "img/events/svg/market-assessment.svg",
            altText: "Market Assessment",
            link: "/consulting/market-assessment-services",
        },
        {
            title: "Competitive Intelligence",
            description: "Our research expertise delivers real-time information that elevates your business decisions",
            imageSrc: "img/events/svg/competitive-Intelligence.svg",
            altText: "Competitive Intelligence",
            link: "/consulting/competitive-intelligence-services",
        },
        {
            title: "Pipeline Assessment",
            description: "We assist in navigating the tricky drug development terrain by providing intelligence assessments",
            imageSrc: "img/events/svg/pipeline-assessment.svg",
            altText: "Pipeline Assessment",
            link: "/consulting/pipeline-assessment-services",
        },
    ]
};
const OurServicesListEular2022 = {
    title: "Delivering value through our expansive service offerings",
    shortDescription: <>
        <p>Taking into account the highly dynamic landscape of rheumatology therapy area, it is of utmost importance to attain competitive edge early on in your business. With our repository of consulting solutions and market research offerings, we are well-positioned to advise you on how to navigate the barriers as well as leverage your strengths and opportunities.</p>
        <p>We impart insights aimed at leveraging competitive advantage and formulating effective business growth strategies. Journey with us to realize opportunities as well as pitfalls, as we make it easier for you to capitalize your return on investment through our expert advice.</p>
    </>,
    btnName:"Explore Rheumatology",
    link: "/report-store.php?therapeutic_areas=Rheumatology",
    items: [
        {
            title: "Portfolio Management",
            description: "Involve us to help you collect insights on which asset could hold the most potential in the future.",
            imageSrc: "img/events/svg/portfolio-management.svg",
            altText: "Portfolio Management",
            link: "/consulting/pharmaceutical-portfolio-management-solutions",
        },
        {
            title: "Market Assessment",
            description: "Our 11-year forecast provides foresight for effective strategy-building for successful market entry",
            imageSrc: "img/events/svg/market-assessment.svg",
            altText: "Market Assessment",
            link: "/consulting/market-assessment-services",
        },
        {
            title: "Competitive Intelligence",
            description: "Our research expertise delivers real-time information that elevates your business decisions",
            imageSrc: "img/events/svg/competitive-Intelligence.svg",
            altText: "Competitive Intelligence",
            link: "/consulting/competitive-intelligence-services",
        },
        {
            title: "Pipeline Assessment",
            description: "We assist in navigating the tricky drug development terrain by providing intelligence assessments",
            imageSrc: "img/events/svg/pipeline-assessment.svg",
            altText: "Pipeline Assessment",
            link: "/consulting/pipeline-assessment-services",
        },
    ]
};
const OurServicesListERS2023 = {
    title: "Delivering value through our expansive service offerings",
    shortDescription: <>
        <p>Considering the rapidly evolving nature of the respiratory therapy field, it is crucial to establish a competitive advantage for your business from the outset. With our extensive range of consulting solutions and market research services, we are ideally positioned to guide you in overcoming challenges and capitalizing on your strengths and opportunities.</p>
        <p>Our expertise is geared towards providing actionable insights for gaining a competitive edge and crafting impactful business growth strategies. Join us on this journey to unlock opportunities and avoid potential pitfalls, as we simplify the process of maximizing your return on investment through our expert guidance.</p>
    </>,
    btnName:"Explore Respiratory",
    link: "/report-store.php?therapeutic_areas=Respiratory",
    items: [
        {
            title: "Portfolio Management",
            description: "Partner with us to discover which assets might offer the most potential in the future",
            imageSrc: "img/events/svg/portfolio-management.svg",
            altText: "Portfolio Management",
            link: "/consulting/pharmaceutical-portfolio-management-solutions",
        },
        {
            title: "Market Assessment",
            description: "Our comprehensive 11-year projection offers valuable insights to inform strategic decision-making for a successful market entry.",
            imageSrc: "img/events/svg/market-assessment.svg",
            altText: "Market Assessment",
            link: "/consulting/market-assessment-services",
        },
        {
            title: "Competitive Intelligence",
            description: "Access our research prowess for timely insights that enhance your business decisions",
            imageSrc: "img/events/svg/competitive-Intelligence.svg",
            altText: "Competitive Intelligence",
            link: "/consulting/competitive-intelligence-services",
        },
        {
            title: "Pipeline Assessment",
            description: "We offer support in maneuvering through the challenging drug development process with our insightful intelligence assessments",
            imageSrc: "img/events/svg/pipeline-assessment.svg",
            altText: "Pipeline Assessment",
            link: "/consulting/pipeline-assessment-services",
        },
    ]
};
const OurServicesListATS2024 = {
    title: "Delivering value through our expansive service offerings",
    shortDescription: <>
        <p>Considering the ever-changing nature of the respiratory therapy field, it&apos;s crucial to establish a competitive advantage for your business as soon as possible. Our range of consulting services and market research resources positions us well to guide you through obstacles while maximizing your strengths and opportunities.</p>
        <p>We provide valuable insights to enhance your competitive edge and develop successful growth strategies. Join us on a journey to uncover opportunities and avoid pitfalls, as we simplify the process of optimizing your return on investment through our expert guidance.</p>
    </>,
    btnName:"Explore Respiratory",
    link: "/report-store.php?therapeutic_areas=Respiratory Diseases",
    items: [
        {
            title: "Portfolio Management",
            description: "Involve us to help you collect insights on which asset could hold the most potential in the future.",
            imageSrc: "img/events/svg/portfolio-management.svg",
            altText: "Portfolio Management",
            link: "/consulting/pharmaceutical-portfolio-management-solutions",
        },
        {
            title: "Market Assessment",
            description: "Our 11-year forecast provides foresight for effective strategy-building for successful market entry",
            imageSrc: "img/events/svg/market-assessment.svg",
            altText: "Market Assessment",
            link: "/consulting/market-assessment-services",
        },
        {
            title: "Competitive Intelligence",
            description: "Our research expertise delivers real-time information that elevates your business decisions",
            imageSrc: "img/events/svg/competitive-Intelligence.svg",
            altText: "Competitive Intelligence",
            link: "/consulting/competitive-intelligence-services",
        },
        {
            title: "Pipeline Assessment",
            description: "We assist in navigating the tricky drug development terrain by providing intelligence assessments",
            imageSrc: "img/events/svg/pipeline-assessment.svg",
            altText: "Pipeline Assessment",
            link: "/consulting/pipeline-assessment-services",
        },
    ]
};
const OurServicesListEsmo2024 = {
    title: "Delivering value through our expansive service offerings",
    shortDescription: <>
        <p>Considering the ever-changing nature of the oncology therapy field, it&apos;s crucial to establish a competitive advantage for your business as soon as possible. Our range of consulting services and market research resources positions us well to guide you through obstacles while maximizing your strengths and opportunities.</p>
        <p>We provide valuable insights to enhance your competitive edge and develop successful growth strategies. Join us on a journey to uncover opportunities and avoid pitfalls, as we simplify the process of optimizing your return on investment through our expert guidance.</p>
    </>,
    btnName:"Explore Oncology",
    link: "/report-store.php?therapeutic_areas=Oncology",
    items: [
        {
            title: "Portfolio Management",
            description: "Involve us to help you collect insights on which asset could hold the most potential in the future.",
            imageSrc: "img/events/svg/portfolio-management.svg",
            altText: "Portfolio Management",
            link: "/consulting/pharmaceutical-portfolio-management-solutions",
        },
        {
            title: "Market Assessment",
            description: "Our 11-year forecast provides foresight for effective strategy-building for successful market entry",
            imageSrc: "img/events/svg/market-assessment.svg",
            altText: "Market Assessment",
            link: "/consulting/market-assessment-services",
        },
        {
            title: "Competitive Intelligence",
            description: "Our research expertise delivers real-time information that elevates your business decisions",
            imageSrc: "img/events/svg/competitive-Intelligence.svg",
            altText: "Competitive Intelligence",
            link: "/consulting/competitive-intelligence-services",
        },
        {
            title: "Pipeline Assessment",
            description: "We assist in navigating the tricky drug development terrain by providing intelligence assessments",
            imageSrc: "img/events/svg/pipeline-assessment.svg",
            altText: "Pipeline Assessment",
            link: "/consulting/pipeline-assessment-services",
        },
    ]
};
const OurServicesListEHA2024 = {
    title: "Delivering value through our expansive service offerings",
    shortDescription: <>
        <p>Considering the ever-changing nature of the hematology therapy field, it&apos;s crucial to establish a competitive advantage for your business as soon as possible. Our range of consulting services and market research resources positions us well to guide you through obstacles while maximizing your strengths and opportunities.</p>
        <p>We provide valuable insights to enhance your competitive edge and develop successful growth strategies. Join us on a journey to uncover opportunities and avoid pitfalls, as we simplify the process of optimizing your return on investment through our expert guidance.</p>
    </>,
    btnName:"Explore Hematology",
    link: "/report-store.php?therapeutic_areas=Hematological Disorders",
    items: [
        {
            title: "Portfolio Management",
            description: "Involve us to help you collect insights on which asset could hold the most potential in the future.",
            imageSrc: "img/events/svg/portfolio-management.svg",
            altText: "Portfolio Management",
            link: "/consulting/pharmaceutical-portfolio-management-solutions",
        },
        {
            title: "Market Assessment",
            description: "Our 11-year forecast provides foresight for effective strategy-building for successful market entry",
            imageSrc: "img/events/svg/market-assessment.svg",
            altText: "Market Assessment",
            link: "/consulting/market-assessment-services",
        },
        {
            title: "Competitive Intelligence",
            description: "Our research expertise delivers real-time information that elevates your business decisions",
            imageSrc: "img/events/svg/competitive-Intelligence.svg",
            altText: "Competitive Intelligence",
            link: "/consulting/competitive-intelligence-services",
        },
        {
            title: "Pipeline Assessment",
            description: "We assist in navigating the tricky drug development terrain by providing intelligence assessments",
            imageSrc: "img/events/svg/pipeline-assessment.svg",
            altText: "Pipeline Assessment",
            link: "/consulting/pipeline-assessment-services",
        },
    ]
};
const OurServicesListAACR2025 = {
    title: "Delivering value through our expansive service offerings",
    shortDescription: <>
        <p>Considering the ever-changing nature of the oncology therapy field, it&apos;s crucial to establish a competitive advantage for your business as soon as possible. Our range of consulting services and market research resources positions us well to guide you through obstacles while maximizing your strengths and opportunities.</p>
        <p>We provide valuable insights to enhance your competitive edge and develop successful growth strategies. Join us on a journey to uncover opportunities and avoid pitfalls, as we simplify the process of optimizing your return on investment through our expert guidance.</p>
    </>,
    btnName:"Explore Oncology",
    link: "/report-store.php?therapeutic_areas=Oncology",
    items: [
        {
            title: "Portfolio Management",
            description: "Involve us to help you collect insights on which asset could hold the most potential in the future.",
            imageSrc: "img/events/svg/portfolio-management.svg",
            altText: "Portfolio Management",
            link: "/consulting/pharmaceutical-portfolio-management-solutions",
        },
        {
            title: "Market Assessment",
            description: "Our 10-year forecast provides foresight for effective strategy-building for successful market entry",
            imageSrc: "img/events/svg/market-assessment.svg",
            altText: "Market Assessment",
            link: "/consulting/market-assessment-services",
        },
        {
            title: "Competitive Intelligence",
            description: "Our research expertise delivers real-time information that elevates your business decisions",
            imageSrc: "img/events/svg/competitive-Intelligence.svg",
            altText: "Competitive Intelligence",
            link: "/consulting/competitive-intelligence-services",
        },
        {
            title: "Pipeline Assessment",
            description: "We assist in navigating the tricky drug development terrain by providing intelligence assessments",
            imageSrc: "img/events/svg/pipeline-assessment.svg",
            altText: "Pipeline Assessment",
            link: "/consulting/pipeline-assessment-services",
        },
    ]
};

const OurServicesListEular2024 = {
    title: "Delivering value through our expansive service offerings",
    shortDescription: <>
        <p>Considering the ever-changing nature of the rheumatology therapy field, it&apos;s crucial to establish a competitive advantage for your business as soon as possible. Our range of consulting services and market research resources positions us well to guide you through obstacles while maximizing your strengths and opportunities.</p>
        <p>We provide valuable insights to enhance your competitive edge and develop successful growth strategies. Join us on a journey to uncover opportunities and avoid pitfalls, as we simplify the process of optimizing your return on investment through our expert guidance.</p>
    </>,
    btnName:"Explore Rheumatology",
    link: "/report-store.php",
    items: [
        {
            title: "Portfolio Management",
            description: "Involve us to help you collect insights on which asset could hold the most potential in the future.",
            imageSrc: "img/events/svg/portfolio-management.svg",
            altText: "Portfolio Management",
            link: "/consulting/pharmaceutical-portfolio-management-solutions",
        },
        {
            title: "Market Assessment",
            description: "Our 11-year forecast provides foresight for effective strategy-building for successful market entry",
            imageSrc: "img/events/svg/market-assessment.svg",
            altText: "Market Assessment",
            link: "/consulting/market-assessment-services",
        },
        {
            title: "Competitive Intelligence",
            description: "Our research expertise delivers real-time information that elevates your business decisions",
            imageSrc: "img/events/svg/competitive-Intelligence.svg",
            altText: "Competitive Intelligence",
            link: "/consulting/competitive-intelligence-services",
        },
        {
            title: "Pipeline Assessment",
            description: "We assist in navigating the tricky drug development terrain by providing intelligence assessments",
            imageSrc: "img/events/svg/pipeline-assessment.svg",
            altText: "Pipeline Assessment",
            link: "/consulting/pipeline-assessment-services",
        },
    ]
};
const OurServicesListErs2024 = {
    title: "Delivering value through our expansive service offerings",
    shortDescription: <>
        <p>Considering the ever-changing nature of the rheumatology therapy field, it&apos;s crucial to establish a competitive advantage for your business as soon as possible. Our range of consulting services and market research resources positions us well to guide you through obstacles while maximizing your strengths and opportunities.</p>
        <p>We provide valuable insights to enhance your competitive edge and develop successful growth strategies. Join us on a journey to uncover opportunities and avoid pitfalls, as we simplify the process of optimizing your return on investment through our expert guidance.</p>
    </>,
    btnName:"Explore Respiratory",
    link: "/report-store.php?therapeutic_areas=Respiratory Diseases",
    items: [
        {
            title: "Portfolio Management",
            description: "Involve us to help you collect insights on which asset could hold the most potential in the future.",
            imageSrc: "img/events/svg/portfolio-management.svg",
            altText: "Portfolio Management",
            link: "/consulting/pharmaceutical-portfolio-management-solutions",
        },
        {
            title: "Market Assessment",
            description: "Our 11-year forecast provides foresight for effective strategy-building for successful market entry",
            imageSrc: "img/events/svg/market-assessment.svg",
            altText: "Market Assessment",
            link: "/consulting/market-assessment-services",
        },
        {
            title: "Competitive Intelligence",
            description: "Our research expertise delivers real-time information that elevates your business decisions",
            imageSrc: "img/events/svg/competitive-Intelligence.svg",
            altText: "Competitive Intelligence",
            link: "/consulting/competitive-intelligence-services",
        },
        {
            title: "Pipeline Assessment",
            description: "We assist in navigating the tricky drug development terrain by providing intelligence assessments",
            imageSrc: "img/events/svg/pipeline-assessment.svg",
            altText: "Pipeline Assessment",
            link: "/consulting/pipeline-assessment-services",
        },
    ]
};
const OurServicesListESMO = {
    title: "Providing value through our wide range of services",
    shortDescription: <>
        <p>As the oncology field undergoes continuous transformations, establishing a competitive advantage at the outset of your business is paramount. Leveraging our extensive array of consulting solutions and in-depth market research capabilities, we are well-prepared to counsel you in overcoming obstacles and making the most of your strengths and opportunities.</p>
        <p>Unlock strategic insights for enhancing your competitive advantage and crafting effective business growth plans. Come with us as we reveal opportunities and challenges, making it simpler for you to maximize your ROI through our expert guidance.</p>
    </>,
    btnName:"Explore Oncology",
    link: "/report-store.php?therapeutic_areas=Oncology",
    items: [
        {
            title: "Portfolio Management",
            description: "Involve us to assist you in gathering insights on what asset might be the most promising in the future",
            imageSrc: "img/events/svg/portfolio-management.svg",
            altText: "Portfolio Management",
            link: "/consulting/pharmaceutical-portfolio-management-solutions",
        },
        {
            title: "Market Assessment",
            description: "Our 10-year forecast provides foresight for effective strategy-building for successful market entry",
            imageSrc: "img/events/svg/market-assessment.svg",
            altText: "Market Assessment",
            link: "/consulting/market-assessment-services",
        },
        {
            title: "Competitive Intelligence",
            description: "Our research expertise delivered real-time information that elevates your business decisions",
            imageSrc: "img/events/svg/competitive-Intelligence.svg",
            altText: "Competitive Intelligence",
            link: "/consulting/competitive-intelligence-services",
        },
        {
            title: "Pipeline Assessment",
            description: "We assist in navigating the tricky drug development terrain by providing intelligence assessments",
            imageSrc: "img/events/svg/pipeline-assessment.svg",
            altText: "Pipeline Assessment",
            link: "/consulting/pipeline-assessment-services",
        },
    ]
};

const RelatedOurServices = {
    'ats-conference/ats-2022': OurServicesListATS2022,
    'eular-conference/eular-2022': OurServicesListEular2022,
    'ers-conference/ers-2023': OurServicesListERS2023,
}
const NewsletterList = [
    {
        title: "Gout Market is Anticipated to Expand Due to Active as well as Emerging Pipeline Therapies",
        shortDescription: "Gout is a chronic disease caused by the deposition of monosodium urate (MSU) crystals in joints. It typically presents as an acute, self-limiting inflammatory monoarthritis that affects the joints of the lower limb. Various symptoms of Gout include intense joint pain, joint swelling, stiffness, tenderness, even to light touch, such as from a bedsheet, heat in the affected area, and joint redness.",
        link: "/whitepaper-newsletter/gout-newsletter",
        imageSrc: "img/events/jpg/eular-2022/featured-image.jpg",
        altText: "Gout newsletter"
    }
];
const NewsletterListASCO2024 = [{
    title: "Cancer Treatment Through Novel Biomarkers",
    shortDescription: "Despite the challenges in the discovery of biomarkers. The paradigm of oncology is bright, with many biomarker-specific therapies being in the pipeline. The development of many more targeted therapies is anticipated with the use of biomarkers in the coming years as a significant cancer driver. Through this whitepaper, DelveInsight is representing the use of biomarkers in overcoming the disadvantages of current treatment options and therefore, changing the face of cancer treatment.",
    link: "/whitepaper-newsletter/cancer-treatment-through-novel-biomarkers",
    imageSrc: "img/events/conference/cancer-treatment-through-novel-biomarkers.png",
    altText: "Cancer Treatment Through Novel Biomarkers"
}]
const NewsletterListAts2024 = [
    {
        title: "Asthma Market To Advance At a Great Extent Due To Growing Prevalence of the Disease Worldwide",
        shortDescription: "Asthma is a chronic disease of the air passages characterized by inflammation and narrowing of the airways. The air passages in the lungs become narrow due to inflammation and tightening of the muscles around the small airways. <br/> Get a sneak peek of the Asthma pipeline therapies, clinical trials, deals & tie-ups, and upcoming world-class conferences, as well as track all the recent updates in the Asthma market.",
        link: "/whitepaper-newsletter/asthma-newsletter",
        imageSrc: "img/events/conference/ats-conference/asthma-market-to-advance-at-a-great-extent-due-to-growing-prevalence-of-the-disease-worldwide-new.png",
        altText: "Asthma Market To Advance At a Great Extent Due To Growing Prevalence of the Disease Worldwide"
    }
];

const NewsletterListEha2024 = [
    {
        title: "Major Hike Anticipated in the AML Market Due to the Presence of a Robust Pipeline",
        shortDescription: "Currently, the AML sphere constitutes a robust pipeline with more than 250+ drugs under different phases of development. Even with the presence of so many therapeutic drugs, there is still a need for targeted palliative care interventions and therapies with lower toxicity and better efficacy. Learn about the recent happenings, ongoing research & development, and significant pharmaceutical companies involved in the AML treatment market.",
        link: "/whitepaper-newsletter/aute-myeloid-leukemia-newsletter",
        imageSrc: "img/events/conference/eha-conference/newsletter-and-whitepapers.png",
        altText: "Major Hike Anticipated in the AML Market Due to the Presence of a Robust Pipeline"
    }
];
const NewsletterListAacr2025 = [
    {
        title: "Cancer Treatment Through Novel Biomarkers",
        shortDescription: "Despite the challenges in the discovery of biomarkers. The paradigm of oncology is bright, with many biomarker-specific therapies being in the pipeline. The development of many more targeted therapies is anticipated with the use of biomarkers in the coming years as a significant cancer driver. Through this whitepaper, DelveInsight is representing the use of biomarkers in overcoming the disadvantages of current treatment options and therefore, changing the face of cancer treatment.",
        link: "/whitepaper-newsletter/cancer-treatment-through-novel-biomarkers",
        imageSrc: "img/aacr/newsletter-and-whitepapers.png",
        altText: "Major Hike Anticipated in the AML Market Due to the Presence of a Robust Pipeline"
    }
];

const NewsletterListEular2024 = [
    {
        title: "Rheumatoid Arthritis: Dominating TNF Inhibitors, Soaring Biosimilars, and Safety Profile of JAK Inhibitors",
        shortDescription: "Over the past few years, a dramatic improvement in outcomes of rheumatoid arthritis has been observed due to the direct consequence of paradigm shifts in the treatment. Conventional DMARDs (csDMARDS) and methotrexate (MTX) are part of the first treatment strategy. Learn about the recent happenings, ongoing research & development, and significant pharmaceutical companies involved in the rheumatoid arthritis treatment market.",
        link: "/whitepaper-newsletter/rheumatoid-arthritis-whitepaper",
        imageSrc: "img/events/conference/eular-conference/rheumatoid-arthritis.png",
        altText: "Rheumatoid Arthritis"
    }
];
const NewsletterListErs2024 = [
    {
        title: "Asthma Market To Advance At a Great Extent Due To Growing Prevalence of the Disease Worldwide",
        shortDescription: "Asthma is a chronic disease of the air passages characterized by inflammation and narrowing of the airways. The air passages in the lungs become narrow due to inflammation and tightening of the muscles around the small airways. Get a sneak peek of the Asthma pipeline therapies, clinical trials, deals & tie-ups, and upcoming world-class conferences, as well as track all the recent updates in the Asthma market.",
        link: "/whitepaper-newsletter/asthma-newsletter",
        imageSrc: "conference/asthma-market-to-advance-at-a-great-extent-due-to-growing-prevalence-of-the-disease-worldwide.png",
        altText: "Asthma Market"
    }
];
const NewsletterListEsmo2024 = [
    {
        title: "Cancer Treatment Through Novel Biomarkers",
        shortDescription: "Despite the challenges in the discovery of biomarkers. The paradigm of oncology is bright, with many biomarker-specific therapies being in the pipeline. The development of many more targeted therapies is anticipated with the use of biomarkers in the coming years as a significant cancer driver. Through this whitepaper, DelveInsight is representing the use of biomarkers in overcoming the disadvantages of current treatment options and therefore, changing the face of cancer treatment.",
        link: "/whitepaper-newsletter/cancer-treatment-through-novel-biomarkers",
        imageSrc: "conference/cancer-treatment-through-novel-biomarkers.png",
        altText: "Cancer Treatment Through Novel Biomarkers"
    }
];
const RelatedNewsletter = {
    'ats-conference/ats-2022': NewsletterList,
    'eular-conference/eular-2022': NewsletterList,
    'ers-conference/ers-2023': NewsletterList
}
const EULARBlogList = [
    {
        title: "Competitive Landscape Of COVID-19 Therapies",
        shortDescription: "A mid-sized EU-based firm involved DelveInsight in assessing the developmental pipeline of COVID-19 to assess the competitors across the clinical development pipeline.",
        link: "/case-study/competitive-landscape-of-covid-19-therapies",
        imageSrc: "img/events/jpg/ats-2022/COVID-19-Therapies.jpg",
        altText: "Competitive Landscape Of COVID-19 Therapies",
    },
    {
        title: "Go-To-Market Strategy",
        shortDescription: "A medium pharmaceutical client based in the United States with biosimilar commercial capabilities involved DelveInsight to identify commercial processes and strategies to launch their biosimilar in Europe through Competitive Assessment of their EU based competitors in the same area of interest.",
        link: "/case-study/go-to-market-strategy",
        imageSrc: "img/events/jpg/ats-2022/Market-Strategy.jpg",
        altText: "Go-To-Market Strategy",
    },
    {
        title: "Competitive Intelligence",
        shortDescription: "A large client based in Europe involved DelveInsight to assist in assessing counter-intelligence strategies for them, after evaluation of their level of leakiness among middle and high-level employees. The client was keen to instill a healthy knowledge of Competitive Intelligence approaches and processes amongst them.",
        link: "case-study/competitive-intelligence",
        imageSrc: "img/events/jpg/ats-2022/Competitive-Intelligence.jpg",
        altText: "Competitive Intelligence",
    },
];
const CaseStudyList = [
    {
        link: '/case-study/partner-identification',
        imageSrc: 'img/events/conference/partner-identification.webp',
        altText: 'Partner Identification',
        title: 'Partner Identification',
        shortDescription: "Partners Identification for client’s early-stage oncology-based clinical product for late-stage clinical development & commercialization."
    },
    {
        link: '/case-study/pipeline-assessment',
        imageSrc: 'img/events/conference/pipeline-assessment.webp',
        altText: 'Pipeline Assessment',
        title: 'Pipeline Assessment',
        shortDescription: "Assessment of pipeline activities around the client’s area of interest to support strategic decisions."
    },
    {
        link: '/case-study/cutaneous-squamous-cell-carcinoma-epidemiology-assessment',
        imageSrc: 'img/events/conference/epidemiology-assessment.webp',
        altText: 'Epidemiology Assessment',
        title: 'Epidemiology Assessment',
        shortDescription: "In-depth assessment to understand the epidemiological scenario of Cutaneous Squamous Cell Carcinoma"
    },
    {
        link: '/case-study/immune-checkpoint-inhibitor-market-potential',
        imageSrc: 'img/events/conference/market-assessment.webp',
        altText: 'Market Assessment',
        title: 'Market Assessment',
        shortDescription: "Evaluation of the oncological market potential of the asset gained through the acquisition and collaborations"
    },
    {
        link: '/case-study/oncology-product-assessment-dashboard',
        imageSrc: 'img/events/conference/product-assessment.webp',
        altText: 'Product Assessment',
        title: 'Product Assessment',
        shortDescription: "Performance evaluation of new assets based on the Lead and Lag KPI"
    }
];

const CaseStudyListASCO2024 = [
    {
        link: '/case-study/oncology-assets',
        imageSrc: 'img/events/conference/conference-coverage-in-various-oncology-conferences.png',
        altText: 'Oncology Conferences',
        title: 'Conference Coverage In Various Oncology Conferences',
        shortDescription:'Learn how a big pharma company leveraged conference coverage solutions to comprehensively covert AACR 2022, ASCO 2022, and ESMO 2022 by meticulous conference planning'
    },
    {
        link: '/case-study/conference-intelligence-product-assessment',
        imageSrc: 'img/events/conference/asco-2024-conference-intelligence-around-her2-negative-breast-cancer-assetsi.png',
        altText: 'ASCO 2022 Conference Intelligence Around HER2 Negative Breast Cancer Assets',
        title: 'ASCO 2022 Conference Intelligence Around HER2 Negative Breast Cancer Assets',
        shortDescription:'Discover how a large pharma company utilized CI for the indication HER2 negative breast cancer'
    },
    {
        link: '/case-study/immune-checkpoint-inhibitor-market-potential',
        imageSrc: 'img/events/conference/market-assessment-asco-2024.png',
        altText: 'Market Assessment ASCO 2024',
        title: 'Market Assessment',
        shortDescription:'Find out how a global pharma company evaluated the market potential of asset gained through acquisition'
    },
    {
        link: '/case-study/pipeline-assessment',
        imageSrc: 'img/events/conference/pipeline-assessment-asco-2024.png',
        altText: 'Pipeline Assessment  ASCO 2024',
        title: 'Pipeline Assessment',
        shortDescription:'Learn how a medium pharma company analyzed current pipeline activity of TCR products for cancer'
    },
    {
        link: '/case-study/oncology-product-assessment-dashboard',
        imageSrc: 'img/events/conference/product-assessment-asco-2024.png',
        altText: 'Product Assessment ASCO 2024',
        title: 'Product Assessment',
        shortDescription:'Find out how a global pharma company monitored the performances of its wide variety of newly launched oncology products based on Lead and Lag KPIs'
    },
];

const CaseStudyListAts2024 = [
    {
        link: '/case-study/respiratory-domain-conference-coverage',
        imageSrc: 'img/events/conference/ats-conference/respiratory-domain-conference-coverage-new.png',
        altText: 'Respiratory Domain Conference Coverage',
        title: 'Respiratory Domain Conference Coverage',
        shortDescription: "Get a thorough event monitoring for a client having a portfolio around IPF"
    },
    {
        link: '/case-study/asthma-and-copd-assets',
        imageSrc: 'img/events/conference/ats-conference/conference-coverage-intelligence.png',
        altText: 'Conference Coverage Intelligence',
        title: 'Conference Coverage Intelligence',
        shortDescription: "Discover how a mid-sized European pharma company utilized ATS 2022 conference coverage"
    },
    {
        link: '/case-study/digital-opinion-leader-analysis-case-study',
        imageSrc: 'img/events/conference/ats-conference/digital-opinion-leader-analysis.png',
        altText: 'Digital Opinion Leader Analysis',
        title: 'Digital Opinion Leader Analysis',
        shortDescription: "Find out how a pharma company understood the landscape of DOLs in the UK related to cystic fibrosis"
    },
    {
        link: '/case-study/kol-profiling',
        imageSrc: 'img/events/conference/ats-conference/kols-profiling.png',
        altText: 'KOL Profiling',
        title: 'KOL Profiling',
        shortDescription: "Learn how the engagement with respected KOLs bolstered the client&apos;s reputation as a leader in the pharma industry"
    },
    {
        link: '/case-study/due-diligence',
        imageSrc: 'img/events/conference/ats-conference/due-diligence.png',
        altText: 'Due Diligence',
        title: 'Due Diligence',
        shortDescription: "Discover how a mid-pharma client gained a level of confidence in their soon-to-be partner for manufacturing their therapeutics"
    }
];
const CaseStudyListEular2024 = [
    {
        link: '/case-study/immune-checkpoint-inhibitor-market-potential',
        imageSrc: 'img/events/conference/eular-conference/market-assessment.png',
        altText: 'Market Assessment',
        title: 'Market Assessment',
        shortDescription: "Find out how a global pharma company evaluated the market potential of asset gained through acquisition"
    },
    {
        link: '/case-study/r-and-d-landscape-assessment',
        imageSrc: 'img/events/conference/eular-conference/r&d-landscape-assessment.png',
        altText: 'R&D Landscape Assessment',
        title: 'R&D Landscape Assessment',
        shortDescription: "Discover how the large pharma client develops API and FD using CDMO competence"
    },
    {
        link: '/case-study/kol-profiling',
        imageSrc: 'img/events/conference/eular-conference/kol-profiling.png',
        altText: 'KOL Profiling',
        title: 'KOL Profiling',
        shortDescription: "Learn how the engagement with respected KOLs bolstered the client&apos;s reputation as a leader in the pharma industry"
    },
    {
        link: '/case-study/due-diligence',
        imageSrc: 'img/events/conference/eular-conference/due-diligence.png',
        altText: 'Due Diligence',
        title: 'Due Diligence',
        shortDescription: "Discover how a mid-pharma client gained a level of confidence in their soon-to-be partner for manufacturing their therapeutics"
    },
    {
        link: '/case-study/ci-news-monitoring-case-study',
        imageSrc: 'img/events/conference/eular-conference/competitive-intelligence-news-monitoring.png',
        altText: 'Competitive Intelligence News Monitoring',
        title: 'Competitive Intelligence News Monitoring',
        shortDescription: "Explore how thorough CI news monitoring helps the client to make proactive decisions that align with their business objectives."
    }
];
const CaseStudyListErs2024 = [
    {
        link: '/case-study/respiratory-domain-conference-coverage',
        imageSrc: 'conference/respiratory-domain-conference-coverage.png',
        altText: 'Respiratory Domain Conference Coverage',
        title: 'Respiratory Domain Conference Coverage',
        shortDescription: "Get a thorough event monitoring for a client having a portfolio around IPF."
    },
    {
        link: '/case-study/asthma-and-copd-assets',
        imageSrc: 'conference/conference-coverage-intelligence.png',
        altText: 'Conference Coverage Intelligence',
        title: 'Conference Coverage Intelligence',
        shortDescription: "Discover how a mid-sized European pharma company utilized ATS 2022 conference coverage"
    },
    {
        link: '/case-study/kol-profiling',
        imageSrc: 'conference/kol-profiling.png',
        altText: 'KOL Profiling',
        title: 'KOL Profiling',
        shortDescription: "Learn how the engagement with respected KOLs bolstered the client&apos;s reputation as a leader in the pharma industry"
    },
    {
        link: '/case-study/due-diligence',
        imageSrc: 'conference/due-diligence.png',
        altText: 'Due Diligence',
        title: 'Due Diligence',
        shortDescription: "Discover how a mid-pharma client gained a level of confidence in their soon-to-be partner for manufacturing their therapeutics"
    },
    {
        link: '/case-study/digital-opinion-leader-analysis-case-study',
        imageSrc: 'conference/digital-opinion-leader-analysis.png',
        altText: 'Digital Opinion Leader Analysis',
        title: 'Digital Opinion Leader Analysis',
        shortDescription: "Find out how a pharma company understood the landscape of DOLs in the UK related to cystic fibrosis."
    }
];

const CaseStudyListEha2024 = [
    {
        link: '/case-study/oncology-assets',
        imageSrc: 'img/events/conference/eha-conference/conference -coverage-in-various-oncology-conferences.png',
        altText: 'Conference Coverage In Various Oncology Conferences',
        title: 'Conference Coverage In Various Oncology Conferences',
        shortDescription: "Learn how a big pharma company leveraged conference coverage solutions to comprehensively cover ASCO 2024, ESMO 2023, EHA, and others by meticulous conference planning."
    },
    {
        link: '/case-study/immune-checkpoint-inhibitor-market-potential',
        imageSrc: 'img/events/conference/eha-conference/market-assessment.png',
        altText: 'Market Assessment',
        title: 'Market Assessment',
        shortDescription: "Find out how a global pharma company evaluated the market potential of asset gained through acquisition."
    },
    {
        link: '/case-study/oncology-product-assessment-dashboard',
        imageSrc: 'img/events/conference/eha-conference/product-assessment.png',
        altText: 'Product Assessment',
        title: 'Product Assessment',
        shortDescription: "Find out how a global pharma company monitored the performances of its wide variety of newly launched oncology products based on Lead and Lag KPIs"
    },
    {
        link: '/case-study/r-and-d-landscape-assessment',
        imageSrc: 'img/events/conference/eha-conference/r&d-landscape-assessment.png',
        altText: 'R&D Landscape Assessment',
        title: 'R&D Landscape Assessment',
        shortDescription: "Discover how the large pharma client develops API and FD using CDMO competence."
    },
    {
        link: '/case-study/kol-profiling',
        imageSrc: 'img/events/conference/eha-conference/kol-profiling.png',
        altText: 'KOL Profiling',
        title: 'KOL Profiling',
        shortDescription: "Learn how the engagement with respected KOLs bolstered the client&apos;s reputation as a leader in the pharma industry."
    }
];
const CaseStudyListAacr2025 = [
    {
        link: '/case-study/oncology-assets',
        imageSrc: 'img/aacr/CS_conference-coverage-in-various.png',
        altText: 'Conference Coverage In Various Oncology Conferences',
        title: 'Conference Coverage In Various Oncology Conferences',
        shortDescription: "Learn how a big pharma company leveraged conference coverage solutions to comprehensively covert AACR, ASCO, and ESMO by meticulous conference planning."
    },
    {
        link: '/case-study/immune-checkpoint-inhibitor-market-potential',
        imageSrc: 'img/aacr/CS_market-assessment.png',
        altText: 'Market Assessment',
        title: 'Market Assessment',
        shortDescription: "Find out how a global pharma company evaluated the market potential of assets gained through the  acquisition."
    },
    {
        link: '/case-study/oncology-product-assessment-dashboard',
        imageSrc: 'img/aacr/CS_product-assessment.png',
        altText: 'Product Assessment',
        title: 'Product Assessment',
        shortDescription: "Find out how a global pharma company monitored the performances of its wide variety of newly launched oncology products based on Lead and Lag KPIs."
    },
];

const CaseStudyListEsmo2024 = [
    {
        link: '/case-study/esmo-conference-coverage',
        imageSrc: 'case-studies/1714367075189-conference-coverage-01.png',
        altText: 'Conference Coverage For Competitive Intelligence Insights On Liquid Biopsy',
        title: 'Conference Coverage For Competitive Intelligence Insights On Liquid Biopsy',
        shortDescription: "Discover how a leading Diagnostic Company monitored the activities and data readouts of competitor liquid biopsy tests at the ESMO 2023"
    },
    {
        link: '/case-study/oncology-assets',
        imageSrc: 'img/casestudies/1701690694-conference-coverage-in-various-oncology-conferences.png',
        altText: 'Conference Coverage In Various Oncology Conferences',
        title: 'Conference Coverage In Various Oncology Conferences',
        shortDescription: "Learn how a big pharma company leveraged conference coverage solutions to comprehensively covert AACR, ASCO, and ESMO by meticulous conference planning"
    },
    {
        link: '/case-study/ci-news-monitoring-case-study',
        imageSrc: 'case-studies/1711454595923-competitive-intelligence-news-monitoring-01.png',
        altText: 'Competitive Intelligence News Monitoring',
        title: 'Competitive Intelligence News Monitoring',
        shortDescription: "Discover how CI news monitoring significantly impacts your business growth"
    },
    {
        link: '/case-study/kol-profiling',
        imageSrc: 'case-studies/1714373372504-kol-profiling-01.png',
        altText: 'KOL Profiling',
        title: 'KOL Profiling',
        shortDescription: "Learn how the engagement with respected KOLs bolstered the client&apos;s reputation as a leader in the pharma industry."
    },
    {
        link: '/case-study/due-diligence',
        imageSrc: 'img/casestudies/1701690201-due-diligence.png',
        altText: 'Due Diligence',
        title: 'Due Diligence',
        shortDescription: "Discover how a mid-pharma client gained a level of confidence in their soon-to-be partner for manufacturing their therapeutics."
    }
];

const ERSBlogList = [
    {
        title: "Competitive Landscape Of COVID-19 Therapies",
        shortDescription: "A mid-sized EU-based firm involved DelveInsight in assessing the developmental pipeline of COVID-19 to assess the competitors across the clinical development pipeline.",
        link: "/case-study/competitive-landscape-of-covid-19-therapies",
        imageSrc: "img/events/jpg/ats-2022/COVID-19-Therapies.jpg",
        altText: "Competitive Landscape Of COVID-19 Therapies",
    },
    {
        title: "Go-To-Market Strategy",
        shortDescription: "A medium pharmaceutical client based in the United States with biosimilar commercial capabilities involved DelveInsight to identify commercial processes and strategies to launch their biosimilar in Europe through Competitive Assessment of their EU based competitors in the same area of interest.",
        link: "/case-study/go-to-market-strategy",
        imageSrc: "img/events/jpg/ats-2022/Market-Strategy.jpg",
        altText: "Go-To-Market Strategy",
    },
    {
        title: "Competitive Intelligence",
        shortDescription: "A large client based in Europe involved DelveInsight to assist in assessing counter-intelligence strategies for them, after evaluation of their level of leakiness among middle and high-level employees. The client was keen to instill a healthy knowledge of Competitive Intelligence approaches and processes amongst them.",
        link: "case-study/competitive-intelligence",
        imageSrc: "img/events/jpg/ats-2022/Competitive-Intelligence.jpg",
        altText: "Competitive Intelligence",
    },
];
const ATSBlogList = [
    {
        title: "Competitive Landscape Of COVID-19 Therapies",
        shortDescription: "A mid-sized EU-based firm involved DelveInsight in assessing the developmental pipeline of COVID-19 to assess the competitors across the clinical development pipeline.",
        link: "/case-study/competitive-landscape-of-covid-19-therapies",
        imageSrc: "img/events/jpg/ats-2022/COVID-19-Therapies.jpg",
        altText: "Competitive Landscape Of COVID-19 Therapies",
    },
    {
        title: "Go-To-Market Strategy",
        shortDescription: "A medium pharmaceutical client based in the United States with biosimilar commercial capabilities involved DelveInsight to identify commercial processes and strategies to launch their biosimilar in Europe through Competitive Assessment of their EU based competitors in the same area of interest.",
        link: "/case-study/go-to-market-strategy",
        imageSrc: "img/events/jpg/ats-2022/Market-Strategy.jpg",
        altText: "Go-To-Market Strategy",
    },
    {
        title: "Competitive Intelligence",
        shortDescription: "A large client based in Europe involved DelveInsight to assist in assessing counter-intelligence strategies for them, after evaluation of their level of leakiness among middle and high-level employees. The client was keen to instill a healthy knowledge of Competitive Intelligence approaches and processes amongst them.",
        link: "case-study/competitive-intelligence",
        imageSrc: "img/events/jpg/ats-2022/Competitive-Intelligence.jpg",
        altText: "Competitive Intelligence",
    },
];
const caseStudyData = {
    'ats-conference/ats-2022': ATSBlogList,
    'eular-conference/eular-2022': EULARBlogList,
    'ers-conference/ers-2023': ERSBlogList
}
const ReportList = [
    {
        altText: "Report",
        imagSrc: "img/events/jpg/eular-2022/reports/1.png",
        link: "/report-store/adult-onset-stills-disease-epidemiology-forecast"
    },
    {
        altText: "Report",
        imagSrc: "img/events/jpg/eular-2022/reports/2.png",
        link: "/report-store/ankylosing-spondylitis-as-market"
    },
    {
        altText: "Report",
        imagSrc: "img/events/jpg/eular-2022/reports/3.png",
        link: "/report-store/axial-spondyloarthritis-market"
    },
    {
        altText: "Report",
        imagSrc: "img/events/jpg/eular-2022/reports/4.png",
        link: "/report-store/behcet-syndrome-market"
    },
    {
        altText: "Report",
        imagSrc: "img/events/jpg/eular-2022/reports/5.png",
        link: "/report-store/fibromyalgia-epidemiology-forecast"
    },
    {
        altText: "Report",
        imagSrc: "img/events/jpg/eular-2022/reports/6.png",
        link: "/report-store/gout-market"
    },
    {
        altText: "Report",
        imagSrc: "img/events/jpg/eular-2022/reports/7.png",
        link: "/report-store/juvenile-idiopathic-arthritis-jia-epidemiology-forecast"
    },
    {
        altText: "Report",
        imagSrc: "img/events/jpg/eular-2022/reports/8.png",
        link: "/report-store/systemic-sclerosis-epidemiology-forecast"
    },
    {
        altText: "Report",
        imagSrc: "img/events/jpg/eular-2022/reports/9.png",
        link: "/report-store/lupus-nephritis-market"
    },
    {
        altText: "Report",
        imagSrc: "img/events/jpg/eular-2022/reports/10.png",
        link: "/report-store/osteoarthritis-pipeline-insight"
    },
    {
        altText: "Report",
        imagSrc: "img/events/jpg/eular-2022/reports/11.png",
        link: "/report-store/polymyalgia-rheumatica-epidemiology-forecast"
    },
    {
        altText: "Report",
        imagSrc: "img/events/jpg/eular-2022/reports/12.png",
        link: "/report-store/psoriatic-arthritis-epidemiology-forecast"
    },
    {
        altText: "Report",
        imagSrc: "img/events/jpg/eular-2022/reports/13.png",
        link: "/report-store/rheumatoid-arthritis-ra-epidemiology-forecast"
    },
    {
        altText: "Report",
        imagSrc: "img/events/jpg/eular-2022/reports/14.png",
        link: "/report-store/sjogrens-syndrome-epidemiology-forecast"
    },
    {
        altText: "Report",
        imagSrc: "img/events/jpg/eular-2022/reports/15.png",
        link: "/report-store/systemic-lupus-erythematosus-epidemiology-forecast"
    },
    {
        altText: "Report",
        imagSrc: "img/events/jpg/eular-2022/reports/16.png",
        link: "/report-store/juvenile-rheumatoid-arthritis-epidemiology-forecast"
    },
];
const atsDataList2022 = [
    {
        imagSrc: "img/events/png/reports/1.png",
        link: "/report-store/acute-lung-injury-market",
        altText: "acute lung injury market"
    },
    {
        imagSrc: "img/events/png/reports/2.png",
        link: "/report-store/asthma-market",
        altText: "asthma market"
    },
    {
        imagSrc: "img/events/png/reports/3.png",
        link: "/report-store/bronchiectasis-market",
        altText: "bronchiectasis market"
    },
    {
        imagSrc: "img/events/png/reports/4.png",
        link: "/report-store/chronic-obstructive-pulmonary-disease-copd-market",
        altText: "chronic obstructive pulmonary disease copd market"
    },
    {
        imagSrc: "img/events/png/reports/5.png",
        link: "/report-store/chronic-pulmonary-hypertension-market",
        altText: "chronic pulmonary hypertension market"
    },
    {
        imagSrc: "img/events/png/reports/6.png",
        link: "/report-store/chronic-thromboembolic-pulmonary-hypertension-cteph-market",
        altText: "chronic thromboembolic pulmonary hypertension cteph market"
    },
    {
        imagSrc: "img/events/png/reports/7.png",
        link: "/report-store/idiopathic-pulmonary-fibrosis-market",
        altText: "idiopathic pulmonary fibrosis market"
    },
    {
        imagSrc: "img/events/png/reports/8.png",
        link: "/report-store/interstitial-lung-disease-market",
        altText: "interstitial lung disease market"
    },
    {
        imagSrc: "img/events/png/reports/9.png",
        link: "/report-store/bronchial-neoplasm-market",
        altText: "bronchial neoplasm market"
    },
    {
        imagSrc: "img/events/png/reports/10.png",
        link: "/report-store/chronic-pulmonary-infections-due-to-pseudomonas-aeruginosa-in-patients-with-cystic-fibrosis-market",
        altText: "chronic pulmonary infections"
    },
    {
        imagSrc: "img/events/png/reports/11.png",
        link: "/report-store/eosinophilic-asthma-market",
        altText: "eosinophilic asthma market"
    },
    {
        imagSrc: "img/events/png/reports/12.png",
        link: "/report-store/peripheral-arterial-disease-pad-pulmonary-vascular-disease-pvd-market",
        altText: "peripheral arterial disease"
    },
    {
        imagSrc: "img/events/png/reports/13.png",
        link: "/report-store/insomnia-market",
        altText: "insomnia market"
    },
    {
        imagSrc: "img/events/png/reports/14.png",
        link: "/report-store/narcolepsy-market",
        altText: "narcolepsy market"
    },
    {
        imagSrc: "img/events/png/reports/15.png",
        link: "/report-store/tuberculosis-market",
        altText: "tuberculosis market"
    },
    {
        imagSrc: "img/events/png/reports/16.png",
        link: "/report-store/non-cystic-fibrosis-bronchiectasis-ncfb-market",
        altText: "non cystic fibrosis"
    }
]
const atsDataList2024 = [
    {
        imagSrc: "img/events/conference/ats-conference/cough-in-idiopathic-pulmonary-fibrosis.png",
        link: "/report-store/cough-in-idiopathic-pulmonary-fibrosis-ipf-market",
        altText: "Cough in Idiopathic pulmonary fibrosis"
    },
    {
        imagSrc: "img/events/conference/ats-conference/acute-respiratory-distress-syndrome.png",
        link: "/report-store/acute-respiratory-distress-syndrome-ards-market",
        altText: "Acute Respiratory Distress Syndrome"
    },
    {
        imagSrc: "img/events/conference/ats-conference/chronic-obstructive-pulmonary-disease.png",
        link: "/report-store/chronic-obstructive-pulmonary-disease-copd-market",
        altText: "Chronic Obstructive Pulmonary Disease"
    },
    {
        imagSrc: "img/events/conference/ats-conference/non-cystic-fibrosis-bronchiectasis.png",
        link: "/report-store/non-cystic-fibrosis-bronchiectasis-market",
        altText: "Non-Cystic Fibrosis Bronchiectasis"
    },
    {
        imagSrc: "img/events/conference/ats-conference/acute-lung-injury.png",
        link: "/report-store/acute-lung-injury-market",
        altText: "Acute Lung Injury Market"
    },
    {
        imagSrc: "img/events/conference/ats-conference/allergic-rhinitis.png",
        link: "/report-store/allergic-rhinitis-market",
        altText: "Allergic Rhinitis Market"
    },
    {
        imagSrc: "img/events/conference/ats-conference/asthma-rhinitis.png",
        link: "/report-store/asthma-market",
        altText: "Asthma Market"
    },
    {
        imagSrc: "img/events/conference/ats-conference/insomnia.png",
        link: "/report-store/insomnia-market",
        altText: "Insomnia Market"
    },
    {
        imagSrc: "img/events/conference/ats-conference/chronic-refractory-cough.png",
        link: "/report-store/chronic-refractory-cough-market",
        altText: "Chronic Refractory Cough"
    },
    {
        imagSrc: "img/events/conference/ats-conference/community-acquired-bacterial-pneumonia.png",
        link: "/report-store/community-acquired-bacterial-pneumonia-cabp-market",
        altText: "Community-Acquired Bacterial Pneumonia"
    },
    {
        imagSrc: "img/events/conference/ats-conference/cystic-fibrosis.png",
        link: "/report-store/cystic-fibrosis-market",
        altText: "Cystic Fibrosis Market Insight"
    },
    {
        imagSrc: "img/events/conference/ats-conference/idiopathic-pulmonary-fibrosis.png",
        link: "/report-store/idiopathic-pulmonary-fibrosis-market",
        altText: "Idiopathic Pulmonary Fibrosis Market"
    },
    {
        imagSrc: "img/events/conference/ats-conference/chronic-insomnia.png",
        link: "/report-store/chronic-insomnia-market",
        altText: "Chronic Insomnia Market"
    },
    {
        imagSrc: "img/events/conference/ats-conference/narcolepsy.png",
        link: "/report-store/narcolepsy-market",
        altText: "Narcolepsy Market"
    },
    {
        imagSrc: "img/events/conference/ats-conference/obstructive-sleep-apnea.png",
        link: "/report-store/obstructive-sleep-apnea-osa-market",
        altText: "Obstructive Sleep Apnea Market"
    }
]

const aacrDataList2025 = [
    {
        imagSrc: "img/aacr/non-small-cell-lung-cancer-nsclc.png",
        link: "/report-store/non-small-cell-lung-cancer-nsclc-market",
        altText: "Non-Small Cell Lung Cancer - Market Insight, Epidemiology and Market Forecast -2034",
    },
    {
        imagSrc: "img/aacr/interleukin-2-market.png",
        link: "/report-store/interleukin-2-market-forecast",
        altText: "Interleukin-2–Market Size, Target Population, Competitive Landscape & Market Forecast - 2034"
    },
    {
        imagSrc: "img/aacr/regulatory-t-cells-tregs-market.png",
        link: "/report-store/regulatory-t-cells-tregs-market-forecast",
        altText: "Regulatory T cells (Tregs) – Market Size, Target Population, Competitive Landscape & Market Forecast – 2034"
    },
    {
        imagSrc: "img/aacr/diffuse-large-b-cell-lymphoma.png",
        link: "/report-store/diffuse-large-b-cell-lymphoma-market",
        altText: "Diffuse Large B-cell Lymphoma Market Insight, Epidemiology And Market Forecast - 2034"
    },
    {
        imagSrc: "img/aacr/follicular-lymphoma-market.png",
        link: "/report-store/follicular-lymphoma-market-insights",
        altText: "Follicular Lymphoma - Market Insight, Epidemiology And Market Forecast - 2034"
    },
    {
        imagSrc: "img/aacr/utaneous-t-cell-lymphoma-ctcl.png",
        link: "/report-store/cutaneous-t-cell-lymphoma-ctcl-market",
        altText: "Cutaneous T-Cell Lymphoma Market Insight, Epidemiology And Market Forecast - 2034"
    },
    {
        imagSrc: "img/aacr/peripheral-t-cell-lymphoma-ptcl-market.png",
        link: "/report-store/peripheral-t-cell-lymphoma-ptcl-market",
        altText: "Peripheral T-Cell Lymphoma Market Insight, Epidemiology And Market Forecast - 2034"
    },
    {
        imagSrc: "img/aacr/prostate-cancer-market.png",
        link: "/report-store/prostate-cancer-market-insight",
        altText: "Prostate Cancer - Market Insight, Epidemiology And Market Forecast - 2034"
    },
    {
        imagSrc: "img/aacr/biliary-tract-cancers-btcs.png",
        link: "/report-store/biliary-tract-cancers-btcs-market",
        altText: "Biliary Tract Cancer Market Insight, Epidemiology And Market Forecast - 2034"
    },
    {
        imagSrc: "img/aacr/multiple-myeloma.png",
        link: "/report-store/multiple-myeloma-market",
        altText: "Multiple Myeloma Market Insight, Epidemiology And Market Forecast - 2034"
    },
]

const ehaDataList2024 = [
    {
        imagSrc: "img/events/conference/eha-conference/acute-lymphocytic-leukemia-market.png",
        link: "/report-store/acute-lymphocytic-leukemia-all-market",
        altText: "Acute lymphocytic leukemia (ALL) - Market Insight"
    },
    {
        imagSrc: "img/events/conference/eha-conference/alpha-thalassemia-market.png",
        link: "/report-store/alpha-thalassemia-market",
        altText: "Alpha Thalassemia - Market Insight"
    },
    {
        imagSrc: "img/events/conference/eha-conference/atypical-hemolytic-uremic-syndrome-(ahus)-market.png",
        link: "/report-store/atypical-hemolytic-uremic-syndrome-ahus-market",
        altText: "Atypical Hemolytic Uremic Syndrome (aHUS) - Market Insight"
    },
    {
        imagSrc: "img/events/conference/eha-conference/beta-thalassemia-market.png",
        link: "/report-store/beta-thalassemia-market",
        altText: "Beta Thalassemia - Market Insight"
    },
    {
        imagSrc: "img/events/conference/eha-conference/car-t-cell-therapy-for-multiple-myeloma-market.png",
        link: "/report-store/car-t-cell-therapy-for-multiple-myeloma-market",
        altText: "CAR T-Cell Therapy for Multiple Myeloma - Market Insight"
    },
    {
        imagSrc: "img/events/conference/eha-conference/cold-agglutinin-disease-market.png",
        link: "/report-store/cold-agglutinin-disease-cad-market",
        altText: "Cold Agglutinin Disease Market Insight"
    },
    {
        imagSrc: "img/events/conference/eha-conference/hemophilia-market.png",
        link: "/report-store/hemophilia-market",
        altText: "Hemophilia Market"
    },
    {
        imagSrc: "img/events/conference/eha-conference/hemophilia-a-market.png",
        link: "/report-store/hemophilia-a2030-market",
        altText: "Hemophilia A - Market Insight"
    },
    {
        imagSrc: "img/events/conference/eha-conference/warm-autoimmune-hemolytic-anemia-(waiha)-market.png",
        link: "/report-store/warm-autoimmune-hemolytic-anemia-waiha-market",
        altText: "Warm Autoimmune Hemolytic Anemia (Waiha) Market"
    },
    {
        imagSrc: "img/events/conference/eha-conference/juvenile-rheumatoid-arthritis-market.png",
        link: "/report-store/juvenile-rheumatoid-arthritis-market",
        altText: "Juvenile Rheumatoid Arthritis - Market Insight"
    },
]
const eularDataList2024 = [
    {
        imagSrc: "img/events/conference/eular-conference/polymyalgia-rheumatica-market.png",
        link: "/report-store/acute-lymphocytic-leukemia-all-market",
        altText: "Polymyalgia Rheumatica Market Insight, Epidemiology And Market Forecast - 2032"
    },
    {
        imagSrc: "img/events/conference/eular-conference/polymyositis-market.png",
        link: "/report-store/polymyositis-market",
        altText: "Polymyositis - Market Insight, Epidemiology And Market Forecast - 2032"
    },
    {
        imagSrc: "img/events/conference/eular-conference/rheumatoid-arthritis-market.png",
        link: "/report-store/rheumatoid-arthritis-ra-market",
        altText: "Rheumatoid Arthritis"
    },
    {
        imagSrc: "img/events/conference/eular-conference/juvenile-rheumatoid-arthritis-market.png",
        link: "/report-store/juvenile-rheumatoid-arthritis-market",
        altText: "Juvenile Rheumatoid Arthritis"
    },
    {
        imagSrc: "img/events/conference/eular-conference/gout-market.png",
        link: "/report-store/gout-market",
        altText: "Gout"
    },
    {
        imagSrc: "img/events/conference/eular-conference/acute-goutflare-market.png",
        link: "/report-store/acute-gout-flare-market",
        altText: "Acute Gout Flare"
    },
    {
        imagSrc: "img/events/conference/eular-conference/chronic-gout-market.png",
        link: "/report-store/chronic-gout-market",
        altText: "Chronic Gout Market"
    },
    {
        imagSrc: "img/events/conference/eular-conference/chronic-refractory-gout-market.png",
        link: "/report-store/chronic-refractory-gout-market",
        altText: "Chronic Refractory Gout"
    },
    {
        imagSrc: "img/events/conference/eular-conference/lupus-nephritis-market.png",
        link: "/report-store/lupus-nephritis-market",
        altText: "Lupus Nephritis Market"
    },
    {
        imagSrc: "img/events/conference/eular-conference/osteoarthritis-market.png",
        link: "/report-store/osteoarthritis-market",
        altText: "Osteoarthritis"
    },
]
const ERSReportList = [
    {
        imagSrc: "img/ers-conference/reports/Acute-Lung-Injury.png",
        link: "/report-store/acute-lung-injury-market",
        altText: "acute lung injury market"
    },
    {
        imagSrc: "img/ers-conference/reports/Asthma-Market.png",
        link: "/report-store/asthma-market",
        altText: "asthma market"
    },
    {
        imagSrc: "img/ers-conference/reports/Bronchiectasis-Market.png",
        link: "/report-store/bronchiectasis-market",
        altText: "bronchiectasis market"
    },
    {
        imagSrc: "img/ers-conference/reports/Chronic-Obstructive-Pulmonary-Disease-Market.png",
        link: "/report-store/chronic-obstructive-pulmonary-disease-copd-market",
        altText: "chronic obstructive pulmonary disease copd market"
    },
    {
        imagSrc: "img/ers-conference/reports/Chronic-Pulmonary-Hypertension-Market-Market.png",
        link: "/report-store/chronic-pulmonary-hypertension-market",
        altText: "chronic pulmonary hypertension market"
    },
    {
        imagSrc: "img/ers-conference/reports/Chronic-Thromboembolic-Pulmonary-Hypertension-(CTEPH)-Market-Market.png",
        link: "/report-store/chronic-thromboembolic-pulmonary-hypertension-cteph-market",
        altText: "chronic thromboembolic pulmonary hypertension cteph market"
    },
    {
        imagSrc: "img/ers-conference/reports/idiopathic-Pulmonary-Fibrosis-Market.png",
        link: "/report-store/idiopathic-pulmonary-fibrosis-market",
        altText: "idiopathic pulmonary fibrosis market"
    },
    {
        imagSrc: "img/ers-conference/reports/Interstitial-Lung-Disease-Market.png",
        link: "/report-store/interstitial-lung-disease-market",
        altText: "interstitial lung disease market"
    },
    {
        imagSrc: "img/ers-conference/reports/Bronchial-Neoplasm-Market-Lung-Disease-Market.png",
        link: "/report-store/bronchial-neoplasm-market",
        altText: "bronchial neoplasm market"
    },
    {
        imagSrc: "img/ers-conference/reports/Chronic-Pulmonary-Infections-Market-Neoplasm-MarketLung-Disease-Market.png",
        link: "/report-store/chronic-pulmonary-infections-due-to-pseudomonas-aeruginosa-in-patients-with-cystic-fibrosis-market",
        altText: "chronic pulmonary infections"
    },
    {
        imagSrc: "img/ers-conference/reports/Eosinophilic-Asthma-MarketPulmonary-Infections-Market-Neoplasm-MarketLung-Disease-Market.png",
        link: "/report-store/eosinophilic-asthma-market",
        altText: "eosinophilic asthma market"
    },
    {
        imagSrc: "img/ers-conference/reports/Pulmonary-Vascular-Disease-Asthma-Market-Pulmonary-Infections-Market-Neoplasm-Market-Lung-Disease-Market.png",
        link: "/report-store/peripheral-arterial-disease-pad-pulmonary-vascular-disease-pvd-market",
        altText: "peripheral arterial disease"
    },
    {
        imagSrc: "img/ers-conference/reports/Tuberculosis-Market.png",
        link: "/report-store/tuberculosis-market",
        altText: "tuberculosis market"
    },
    {
        imagSrc: "img/ers-conference/reports/Non-Cystic-Fibrosis-Bronchiectasis-Market.png",
        link: "/report-store/non-cystic-fibrosis-bronchiectasis-ncfb-market",
        altText: "non cystic fibrosis"
    }
]
const ERS2024ReportList = [
    {
        imagSrc: "conference/bacterial-pneumonia-market.png",
        link: "/report-store/bacterial-pneumonia-market",
        altText: "Bacterial Pneumonia Market Insight"
    },
    {
        imagSrc: "conference/severe-asthma-market.png",
        link: "/report-store/severe-asthma-market",
        altText: "Severe Asthma Market"
    },
    {
        imagSrc: "conference/pulmonary-fibrosis-market.png",
        link: "/report-store/pulmonary-fibrosis-market",
        altText: "Pulmonary Fibrosis"
    },
    {
        imagSrc: "conference/progressive-pulmonary-fibrosis-(ppf)-market.png",
        link: "/report-store/progressive-pulmonary-fibrosis-ppf-market",
        altText: "Progressive Pulmonary Fibrosis"
    },
    {
        imagSrc: "conference/cystic-fibrosis-market.png",
        link: "/report-store/cystic-fibrosis-market",
        altText: "Cystic Fibrosis Market Insight"
    },
    {
        imagSrc: "products/report-image/1724223181299-chronic-refractory-cough-marker-market.png",
        link: "/report-store/chronic-refractory-cough-market",
        altText: "Chronic Refractory Cough"
    },
    {
        imagSrc: "conference/asthma-marker.png",
        link: "/report-store/asthma-market",
        altText: "Asthma Market"
    },
    {
        imagSrc: "conference/allergic-rhinitis-marker.png",
        link: "/report-store/allergic-rhinitis-market",
        altText: "Allergic Rhinitis"
    },
    {
        imagSrc: "conference/acute-lung-injury-market.png",
        link: "/report-store/acute-lung-injury-market",
        altText: "Acute Lung Injury"
    },
    {
        imagSrc: "conference/idiopathic-pulmonary-fibrosis-market.png",
        link: "/report-store/idiopathic-pulmonary-fibrosis-market",
        altText: "Idiopathic Pulmonary Fibrosis Market"
    },
    {
        imagSrc: "conference/cough-in-idiopathic-pulmonary-fibrosis-(ipf)-market.png",
        link: "/report-store/cough-in-idiopathic-pulmonary-fibrosis-ipf-market",
        altText: "Cough in Idiopathic pulmonary fibrosis"
    },
    {
        imagSrc: "conference/pertussis-market.png",
        link: "/report-store/pertussis-market",
        altText: "Pertussis Market Insight"
    },
    {
        imagSrc: "conference/non-cystic-fibrosis-bronchiectasis-(ncfb)-market.png",
        link: "/report-store/non-cystic-fibrosis-bronchiectasis-market",
        altText: "Non-Cystic Fibrosis Bronchiectasis Market"
    },
    {
        imagSrc: "conference/chronic-obstructive-pulmonary-disease-(copd)-marker.png",
        link: "/report-store/chronic-obstructive-pulmonary-disease-copd-market",
        altText: "Chronic Obstructive Pulmonary Disease"
    },
    {
        imagSrc: "conference/acute-respiratory-distress-syndrome-(ards)-marker.png",
        link: "/report-store/acute-respiratory-distress-syndrome-ards-market",
        altText: "Acute Respiratory Distress Syndrome"
    },
]


export {
    OurServicesList,
    OurServicesListESMO,
    OurServicesListATS2024,
    OurServicesListATS2022,
    OurServicesListEular2022,
    OurServicesListEHA2024,
    OurServicesListEular2024,
    OurServicesListErs2024,
    OurServicesListERS2023,
    OurServicesListEsmo2024,
    NewsletterList,
    CaseStudyList,
    ReportList,
    RelatedNewsletter,
    RelatedOurServices,
    CaseStudyListAts2024,
    NewsletterListAts2024,
    NewsletterListEha2024,
    NewsletterListEular2024,
    NewsletterListEsmo2024,
    NewsletterListErs2024,
    atsDataList2022,
    atsDataList2024,
    ehaDataList2024,
    eularDataList2024,
    ERSReportList,
    ERS2024ReportList,
    NewsletterListASCO2024,
    CaseStudyListEular2024,
    CaseStudyListErs2024,
    CaseStudyListASCO2024,
    CaseStudyListEha2024,
    CaseStudyListEsmo2024,
    NewsletterListAacr2025,
    CaseStudyListAacr2025,
    aacrDataList2025,
    OurServicesListAACR2025,
    
}