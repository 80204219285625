import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import BorderBtn from '../../Common/BorderBtn';
import SectionHeading from '../../Common/SectionHeading';

export default function NewsLetterSectionEvent({ data = null }) {
    return (
        <>
            <div className="w-11/12 xl:max-w-screen max-w-screen-2xl flex flex-col justify-center mx-auto lg:my-10 my-6">
                <SectionHeading title="Newsletter and Whitepapers" />
                {data?.map((item, index) => (
                    <div key={index} className='relative lg:mb-6 mb-0'>
                        <div className='flex flex-col lg:flex-row justify-end'>
                            <Image
                                priority={true}
                                className="lg:w-9/12 w-full lg:h-[460px] h-full"
                                alt={item?.altText ? item?.altText : item?.title ? item?.title : 'DelveInsight'}
                                src={process.env.IMG_CDN + item?.imageSrc}
                                height={2000}
                                width={2000}
                            />
                            <div className='lg:absolute relative lg:left-0 lg:-bottom-10 lg:w-5/12 w-full bg-[#eaeaea] lg:p-8 p-4'>
                                <h3 className='text-lg font-semibold'>{item?.title}</h3>
                                <p dangerouslySetInnerHTML={{ __html: item?.shortDescription }}></p>
                                <BorderBtn
                                    customClass="bg-[#1b5df3] border-[#1b5df3] text-white"
                                    buttonTitle="Go to Newsletter"
                                    buttonLink={process.env.APP_URL + item?.link}
                                    style={{ color: "#1b5df3" }}
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}
