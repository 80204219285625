import React from 'react';
import Image from 'next/image';
import SectionHeading from './SectionHeading';
import * as Yup from 'yup';
import CallBackForm from './CallBackForm';

export default function ContactSection({ customWidth, data = null, page_name = "Common Page", form_name = "common-form", endpoint = "", slug = null }) {
  const options = {
    btn_name: "Submit"
  };

  const fields = [
    {
      name: 'name',
      label: '',
      placeholder: 'Full Name*',
      validation: Yup.string().matches(/^[a-zA-Z\s]*$/, 'Name can only contain letters and spaces').required('Name is required'),
      className: 'block text-gray-700 rounded w-full py-2 border-b focus:outline-none focus-visible:border-b border-gray-300 px-4 bg-transparent',
      customClass: 'md:col-span-6 col-span-12'
    },
    {
      name: 'email',
      type: 'email',
      label: '',
      placeholder: 'Add Your Company E-mail Address*',
      validation: Yup.string().email('Invalid email').required('Email is required'),
      className: 'block text-gray-700 rounded w-full py-2 border-b focus:outline-none focus-visible:border-b border-gray-300 px-4 bg-transparent',
      customClass: 'md:col-span-6 col-span-12'
    },
    {
      name: 'message',
      label: '',
      placeholder: 'Please describe your Competitive Intelligence needs briefly',
      type: 'textarea',
      col_row: 2,
      className: 'block text-gray-700 rounded w-full py-2 border-b focus:outline-none focus-visible:border-b border-gray-300 px-4 bg-transparent',
      customClass: 'md:col-span-12 col-span-12'
    },
  ];

  return (
    <>
      {data && (
        <section className="bg-gradient-to-r from-blue-100 to-blue-[#1b5df3]">
          <div className="flex flex-col justify-center w-11/12 py-4 mx-auto xl:max-w-screen max-w-screen-2xl md:py-8">
            <SectionHeading title="Get Expert Consultation Now!" />
            {/* <p className="text-black text-left mt-4 mb-8">
                    {data?.description}
            </p> */}
            <div className="grid grid-cols-12">
              <div className="col-span-12 lg:col-span-5">
                <div className="relative">
                  <Image
                    width={3000}
                    height={3000}
                    src={process.env.IMG_CDN + data?.imageSrc}
                    alt={data?.altText}
                    className={`hidden h-auto lg:block ${customWidth ? 'w-full' : 'w-80'}`}
                  />
                </div>
              </div>
              <div className="col-span-12 lg:col-span-7">
                <div className="lg:pl-8">
                  <h3 className="text-lg font-semibold">{data?.mainTitle}</h3>
                  <div>{data?.shortDescription}</div>
                  <p className="text-black text-left mt-4 mb-8">
                    {data?.description}
                  </p>
                  <CallBackForm
                    fields={fields}
                    options={options}
                    page_name={page_name}
                    form_name={form_name}
                    endpoint={endpoint}
                    slug={slug}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}
