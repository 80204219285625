import React from 'react'
import Image from 'next/image'
import Link from 'next/link'
import BorderBtn from '../../Common/BorderBtn'
import SectionHeading from '../../Common/SectionHeading'

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import { Navigation, Autoplay, EffectCoverflow } from 'swiper/modules';


export default function CaseStudySection({ data = null }) {
    return (
        <>
            {(data) &&
                <>
                    <div className="w-11/12 xl:max-w-screen max-w-screen-2xl mx-auto lg:mt-10 lg:mb-0 mt-6 mb-0">
                        <SectionHeading
                            title="Case Studies"
                        />
                        {/* <div className="grid grid-cols-12 gap-4">
                        {data?.map((item, index) => (
                            <div key={index} className="lg:col-span-4 col-span-12">
                                <div className='bg-white shadow hover:-translate-y-2 hover:transition-all hover:duration-300 hover:ease-out'>
                                    <Link href={process.env.APP_URL+item?.link}>
                                        <Image priority={true}
                                            height={2000}
                                            width={2000}
                                            className="rounded-lg w-full h-full rounded-br-[200px]"
                                            alt={item?.altText ? item?.altText : item?.title ? item?.title : 'DelveInsight'} 
                                            src={process.env.IMG_CDN+item?.imageSrc}
                                        />
                                    </Link>
                                    <div className='p-4'>
                                        <Link href={process.env.APP_URL+item?.link}>
                                            <h3 className="text-xl font-semibold line-clamp-1">{item?.title}</h3>
                                        </Link>
                                        <p className="line-clamp-4">{item?.shortDescription}</p>
                                        <BorderBtn 
                                            customClass="bg-[#1b5df3] border-[#1b5df3] text-white"
                                            buttonTitle="View Case Study"
                                            buttonLink={process.env.APP_URL+item?.link}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div> */}
                        <Swiper
                            modules={[Navigation, Autoplay]}
                            speed={300}
                            loop={true}
                            autoplay={{
                                delay: 3000,
                            }}
                            breakpoints={{
                                680: {
                                    slidesPerView: 1,
                                    spaceBetween: 20,
                                },
                                765: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                                1024: {
                                    slidesPerView: 3,
                                    spaceBetween: 20,
                                },
                            }}
                            className="mySwiper">
                            {data?.map((item, index) => (
                                <SwiperSlide key={index}>
                                    <div className='bg-white shadow rounded mb-2'>
                                        <Link href={process.env.APP_URL + item?.link}>
                                            <Image priority={true}
                                                height={2000}
                                                width={2000}
                                                className="rounded-lg w-full h-full"
                                                alt={item?.altText ? item?.altText : item?.title ? item?.title : 'DelveInsight'}
                                                src={process.env.IMG_CDN + item?.imageSrc}
                                            />
                                        </Link>
                                        <div className='p-4'>
                                            <Link href={process.env.APP_URL + item?.link}>
                                                <h3 className="text-xl font-semibold line-clamp-1">{item?.title}</h3>
                                            </Link>
                                            <p className="line-clamp-2">{item?.shortDescription}</p>
                                            <BorderBtn
                                                customClass="bg-[#1b5df3] border-[#1b5df3] text-white"
                                                buttonTitle="View Case Study"
                                                buttonLink={process.env.APP_URL + item?.link}
                                            />
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </>
            }
        </>
    )
}
